.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2c374e;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Hurricane', cursive;
}

.App-content {
  margin-left: auto;
  margin-right: auto;
}

.App-tabs {
  margin-left: auto;
  margin-right: auto;
  border-bottom: none;
}

.registrationContent {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.entreeCarousel {
  height: 35vh;
}

.entreeCarouselEntry {
  padding-left: 15%;
  padding-right: 15%;
}

.hotel-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15%;
  width: 70%;
  height: auto;
}

.transportation-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15%;
  max-width: 50%;
  height: auto;
}

.map-view {
  border-radius: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.family-member-card-deck {
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.family-member-card {
  margin: 2%;
}

.family-form {
  min-width: 50vw;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 767px) {
  .App-content {
    margin-left: auto;
    margin-right: auto;
    min-height: 80vh;
    max-width: 60vw;
  }

  .registrationContent {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .entreeCarousel {
    height: 110%;
  }

  .entreeCarouselEntry {
    padding-left: 15%;
    padding-right: 15%;
    height: 12em;
  }

  .hotelContent {
    margin-top: 5vh;
  }

  .registryContent {
    margin-top: 5vh;
  }

  .dayOfInfoContent {
    margin-top: 5vh;
  }
}